import React from "react";
import './index.scss';

const DefaultWrapper = ({children, style}) => {
    return(
        <div style={style} className="defaultWrapper">
            <div className="container">
                <div className="content">
                    <div className="content__wrap">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultWrapper;